@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: none;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

h5 {
  position: relative;
  margin: 0;
  margin-top: 8px;
  font-size: 16px;
  left: -20px
}

ul {
  list-style: none
}

h1 {
text-align: center;
}

.app {
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow: hidden;
  color: azure;
}

.space {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  width: 1800px;
  height: 1200px;
  background-image: url(./img/space.jpg) ;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation-name: space;
  animation-duration: 50s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  z-index: 0;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.65);
  z-index: 1;
}

.overlay-header {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: url(./img/overlay_shape-01.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}
/*HEADER*/

.page {
  position: relative;
  z-index: 2;
}


.top_menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top:0px;
  padding: 2px 0px;
  border-radius: 0 0 10px 0;
  transition: 0.5s;
  z-index: 4;
}


.minilogo {
  height: 30px;
  background-color: rgba(0, 6, 23,0.5);
  border-radius: 10px;
  margin: 2px;
}

.minilogo img {
  width: 100px;
  cursor: pointer;
}

.side_menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: space-between;
  height: 100vh;
  width: 30px;
  right: 0;
  transition: 0.5s;
  z-index: 4;
}

.side_menu_button {
  width: 30px;
  height: 24vh;
  padding: 0;
  display: flex;
  font-size: 14px;
  justify-content: start;
  border-radius: 10px 0px 0px 10px;
  position: relative;
  right:-8px;
  transition: 0.3s;
  opacity: 75%;
}


.side_menu_button div {
  transform-origin: bottom left;
  color: white;
  height: 23vh;
  padding: 3px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.side_menu_button div::before {
  content: "• ";
}

.side_menu_button div:hover::before {
  color: #FFCA7F
}

.side_menu_button:hover {
  right: 0px;
  padding-left: 3px;
  opacity: 1;
}

.side_menu_about {
  background-color: #474C69;
}

.side_menu_features {
  background-color: #434376;
}

.side_menu_progress {
  background-color: #41588A;
}

.side_menu_author {
  background-color: #7A90B2;
}


.header {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.lang_menu {
  position: absolute;
  top:0;
  right: 6vw;
  z-index: 5;
}

.lang_menu button {
  background-color: transparent;
  width: 70px;
  opacity: 90%;
  position: relative;
  z-index: 5;
}

.lang_btn {
  color: white;
}

.lang_btn:hover {
  color: #FFCA7F 
}

.lang_selected {
  color: #FFCA7F 
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 50%;
  animation-name: logo;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.logo img {
  width: 70%;
  animation-name: logo;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.logo_text {
  font-size: calc(10px + 1vw);
  margin-top: -10px;
  margin-left: 10vw;
}

.logo_version {
  position: relative;
  font-size: 12px;
  top: 4px;
  left: 170px;
  border: 1px solid #FFCA7F;
  color: #FFCA7F ; 
  padding: 2px 5px;
  border-radius: 20px;
}

.logo_ver {
  font-size: 12px;
  border: 1px solid #FFCA7F;
  color: #FFCA7F ; 
  padding: 2px 5px;
  border-radius: 20px;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu_horizontal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_button {
  font-size: calc(10px + 1.2vw);
  margin-bottom: 8px;
  width: 18.5vw;
  min-width: 220px;
  min-height: 50px;
  height: 7vh;
  border-radius: 50px;
  padding: 0px 20px; 
  color: azure;
  text-align: left;
  cursor: pointer;
}

.menu_button::before {
  content: "• ";
}

.menu_button:hover::before {
  color: #FFCA7F
}

.menu_about {
  position: relative;
  background-color: #474C69;
  left: 20px;
  transition: 0.3s;
}

.menu_about:hover {
  left:25px
}

.menu_features {
  position: relative;
  background-color: #434376;
  transition: 0.3s;
}

.menu_features:hover {
  left: 5px
}

.menu_progress {
  position: relative;
  background-color: #41588A;
  right: 20px;
  transition: 0.3s;
}

.menu_progress:hover {
  right: 15px
}


.menu_author {
  position: relative;
  background-color: #7A90B2;
  right: 40px;
  transition: 0.3s;
}

.menu_author:hover {
  right: 35px
}

/*ABOUT*/
.about {
  width: 100vw;
  height: 60vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  z-index: 3;
}

.about_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
  width: 40vw;
  height: 59vh;
  padding: 5px 5px;
  font-size: 18px;
  color: #0d2049;

}

    .about_text {
      padding: 0 20px;
    }


    .about_heading {
      padding: 5px 10px;
      width:39vw;
      margin: 0;
      text-align: left;
    }

.about_right {
  width: 41vw;
  height: 49.5vh;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  background: rgb(116,116,116);
  background: radial-gradient(circle, rgba(116,116,116,1) 0%, rgba(255,255,255,1) 100%);
  filter: drop-shadow(-2px 2px 10px #505050);
}


/*FEATURES*/

.features {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  z-index: 2;
}

.overlay_features {
  position:absolute;
  width: 100vw;
  height: 100vh;
  top:-8vh;
  left: 0;
  z-index: 2;
  background: url(./img/overlay_shape-02.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
}

.progress {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  z-index: 2;
}

.overlay_progress {
  position:absolute;
  width: 100vw;
  height: 100vh;
  top:-14vh;
  left: 0;
  z-index: 2;
  background: url(./img/overlay_shape-02.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: scale(1,-1);
  z-index: 1;
}

.author {
  position: relative;
  background-color: white;
  width: 100vw;
  height: 60vh;
  color: #0d2049;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  z-index: 2;
  bottom: 20vh;
  border-radius: 0 0 50px 50px 
}

.author_text {
  font-weight: 100;
  font-size: 18px;
  color: #0d2049;
  padding: 0 20px;
  max-width: 70%;
}

.author_links {
  display: flex;
  width: 100%;
  justify-content: center;
}

.portfolio {
  height: 200px;
  width: 200px;
  border-radius: 200px;
  background-color: rgb(181, 228, 250);
  background-image: url(./img/portfolio.png);
  background-size: 100% 100%;
  margin: 30px 50px;
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 22px;
  font-weight: 500;
  transition: 0.2s;
}

.portfolio:hover {
  filter: brightness(105%);
}

.linkedin {
  height: 200px;
  width: 200px;
  border-radius: 200px;
  background-color: rgb(181, 228, 250);
  background-image: url(./img/linkedin.svg);
  background-size: 100% 100%;
  margin: 30px 50px;
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 22px;
  font-weight: 500;
  transition: 0.2;
}

.linkedin:hover {
  filter: brightness(105%);
}

.progress_bar {
  width: 35vw;
  height: 10px;
  background-color: rgba(0, 6, 23,0.8);
  border-radius: 10px;
}

.progress_bar div{
  width: 20vw;
  height: 10px;
  border-radius: 10px 0 0 10px;
  background-color: #41588A;
}

.progress_date {
  font-weight: 100;
  font-size: 12px;
  color:#FFCA7F
}


.img_modal {
  position: absolute;
  width: 120%;
  height: 140%;
  top:-20%;
  left:-10%;
  border-radius: 30px;
  padding:10px;
  background-color: rgba(0, 6, 23,0.8);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



/* Animations */

@keyframes space {
  0%  {background-size: 100vw auto;}
  50% {background-size: 110vw auto;}
  100%  {background-size: 100vw auto;}
}

@keyframes logo {
  0% {transform:scale(0.98)}
  50% {transform:scale(1)}
  100% {transform:scale(0.98)}
}

/* HELPER */
.helper {
  position: fixed;
  font-size: 16px;
  bottom:5px;
  left: 4px;
  color: red;
}

.TEST {
  cursor: pointer;
}

.TEST:hover {
  background-color: red;
}

@media (max-width: 768px) {

  .side_menu {
    display: none;
  }

  .top_menu {
    border-radius: 0;
    width: 100vw;
  }
    
  .minilogo {
    border-radius: 0;
    margin: 0;
  }

  
  .space {
    background-image: url(./img/space.jpg) ;
    background-size: 100% 100%;
    min-width: 100vh;
    transform-origin: center;
    transform: rotate(90deg);
    margin-left: -100vw;
    animation-name: space;
    animation-duration: 50s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes space {
    0%  {background-size: 100% auto;}
    50% {background-size: 110% auto;}
    100%  {background-size: 100% auto;}
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    }

  .logo {
    position: relative;
    right: 10px;
    width: 100%;

  }

      .logo_text {
        width: 60%; 
        font-size: 18px;
      }

      .logo_version {
        position: static;
        font-size: 12px;
        margin-top: 4px;
        border: 1px solid #FFCA7F;
        color: #FFCA7F ; 
        padding: 2px 5px;
        border-radius: 20px;
      }

  .menu {
    position: relative;
    right: 10px;
  }

  .menu_button {
      width: 21vw;
   }

      .menu_about {
        left: 0;
        min-width: fit-content;
        font-size: 22px;
      }

      .menu_about:hover {
        left:0
      }

      .menu_features {
        left: 0;
        font-size: 22px;
      }

      .menu_features:hover {
        left: 0;
      }

      .menu_progress {
        left: 0;
        font-size: 22px;
      }

      .menu_progress:hover {
        left: 0;
      }

      .menu_author {
        left: 0;
        font-size: 22px;
      }

      .menu_author:hover {
        left: 0;
      }

  .about {
    flex-direction: column;
    position: relative;
    justify-content: start;
    bottom: 2vh;
    padding: 0;
    height: 95vh;
  }

      .about_heading {
        width: 90%;
        text-align: center;
      }

      .about_left {
        width: 90%;
        height: 45%;
        text-align: justify;

      }

      .about_right {
        width: 90%;
        height: 45%;
      }

      .author {
        height: fit-content;    
        padding: 10px;
        border-radius: 0
      }
      
      .author_text {
        padding: 0;
        max-width: 80%;
      }
      
      .author_links {
        flex-direction: column;
        justify-content: center;
      }
     
      .progress_bar {
        width: 75vw;
        height: 20px;
        border-radius: 20px;
        background-color: rgba(0, 6, 23,0.8);
      }
      
      .progress_bar div{
        width: 45vw;
        height: 20px;
        border-radius: 20px 0 0 20px;
        background-color: #41588A;
      }


}