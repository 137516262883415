
  
  .features_app_wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    width: 70vw;
    height: 80vh;
    background-color:  rgba(0, 6, 23,0.8);
    border-radius: 20px;
    z-index: 2;
  }
  
  .app_buttons {
    width: 95%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1px;
  }
  
      .app_button {
        width: 33.2%;
        height: 40px;
        font-size: calc(14px + 0.5vw);
        border-radius: 10px 10px 0 0;
        color: white;
        font-weight: 200;
        background-color: rgb(59, 73, 108);
        text-align: center;
        transition: 0.1s; 
      }
  
      .app_button_selected {
        position: relative;
        color: #0d2049;
        font-weight: 500;
        background-color: white;
        z-index: 3;
  }
  
  .app_content {
    background-color: white;
    color: #0d2049;
    height: 83%;
    width: 95%;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 1px 5px rgba(2, 2, 69,0.7);
  }


  .compliant_wrapper {
    width: 95%;
    height: 100%;
    padding: 10px 15px;
    font-size: 18px;
    display:flex;
    flex-direction: column;

  }

  .compliant_box { 
    width: 100%;
    height: 32%;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .compliant_options {
    background-color: #0d2049;
    padding: 0 10px;
    border-radius: 10px;
    
  }

  .compliant_selection {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .compliant_selection span {
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 18px;
  }

  .compliant_selection button {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 250px;
    height: 50px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 100;
    color:  white ;
    background-color: rgb(59, 73, 108)
  }

  .compliant_numbers {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  
  .risk_modal_label {
    width: 100px;
    text-align: center;
    margin-right: 5px;
    font-size: 18px;
  }

  .risk_modal_number {
    width: 100px;
    height: 40px;
    margin-right: 5px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
  }

  .rpn_crit_box {
    display: flex;
  }

  .rpn_crit_box div{
    display: flex;
    flex-direction: column;
  }


  .rpn_label {
    text-align: center;
    width: 120px;
  }

  .rpn {
    width: 120px;
    height: 45px;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    border-radius: 5px;
    color: white
  }

  .criticality_label{
    text-align: center;
    width: 120px;
    margin-left: 10px;
  }

  .rpn_criticality {
    text-align: center;
    width: 120px;
    height: 45;
    font-size: 22px;
    line-height: 45px;
    margin-left: 10px;
  }

  .compliant_custom_wrapper  {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px 10px 0 0 ;
    color: white;
  }

  .compliant_custom {
    display: flex;
  }


.custom_max {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}

.custom_label {
  font-size: 16px;
}

.custom_form {
  display: flex;
  align-items: end;
  width: 100%;
}

.custom_formula_box{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.custom_formula {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.custom_submit {
  border: none;
  font-size: 18px;
  height: 45px;
  width: 100px;
  margin: 2px 0 2px 25px;
  border-radius: 5px;
  background-color:  #434376;
  color:  #FFCA7F ;
  cursor: pointer;
}

.custom_submit {
  filter: brightness(115%);
}

.feature_box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.feature_left {
  position: relative;
  box-sizing: border-box;
  padding: 5px 10px;
  width: 40%;
}

.feature_right {
  box-sizing: border-box;
  width: 60%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.feature_img_box {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.features_img {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.features_img:hover {
  filter: brightness(105%);
}

.feature_img_desc {
  font-size: 14px;
}

.img_modal_text {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.img_modal_desc {
  color: white;
}

.img_modal_num {
  color: white;
}

.feature_img_num {
  max-width: 80%;
  font-size: 14px;
  position: absolute;
  right: 10px;
  bottom: 0
}

.feature_img_btn_right {
  position: absolute;
  background-color: rgba(0, 6, 23,0.8);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 26px;
  color: white;
  text-align: center;
  right: 10px;
}

.feature_img_btn_left {
  position: absolute;
  background-color: rgba(0, 6, 23,0.8);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 26px;
  color: white;
  text-align: center;
  left: 10px;
}

.img_modal_btn_box_right {
  position: absolute;
  z-index: 10;
  right: 0;
  width: 50px;
  height: 100%;
  background-color: rgba(0, 6, 23,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
}

.img_modal_btn_box_right:hover {
  opacity: 1;
}

.img_modal_btn_right {
  position: absolute;
  background-color: rgba(0, 6, 23,0.8);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 26px;
  color: white;
  text-align: center;
  right: 10px;
}

.img_modal_btn_box_left {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 50px;
  height: 100%;
  background-color: rgba(0, 6, 23,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
}

.img_modal_btn_box_left:hover {
  opacity: 1;
}

.img_modal_btn_left {
  position: absolute;
  background-color: rgba(0, 6, 23,0.8);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 26px;
  color: white;
  text-align: center;
}


.feature_help {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: start;
  bottom: 5px;
  font-size: 12px;
}

.feature_help span {
  margin: 0 10px
}


.feature_left li {
  font-weight: 200;
}

.implemented::before {
  content: "✔ ";
  margin-right: 5px;
  color: #4d53ff;
}

.inprogress::before {
  content: "● ";
  margin-right: 8px;
  color: #466aa9;
}

.planned::before {
  content: "? ";
  margin-right: 10px;
  color: #6d6d6d;
}



@media (max-width: 768px) {

  .features_app_wrapper {
    padding: 5px 0px 5px 2px;
    width: 93vw;
    height: 85vh;
    background-color:  rgba(0, 6, 23,0.8);
    border-radius: px;
    z-index: 2;
    position: relative;
    flex-direction: row-reverse;
  }
  
  .feature_img_desc {
    font-size: 12px;
    width: 70vw;
  }

  .img_modal_desc {
    font-size: 12px;
    width: 70vw;
  }

  .feature_left li {
    font-size: 14px
  }


  .app_buttons {
    flex-direction: column;
    width: 10%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .app_button {
    width: 100%;
    height: 33.3%;
    font-size: calc(14px + 0.5vw);
    border-radius: 0;
    color: white;
    font-weight: 200;
    background-color: rgb(59, 73, 108);
    text-align: center;
    transition: 0.1s; 
    right: 1px;
    bottom: 1px;
  }

  .app_button div {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .app_button_selected {
    position: relative;
    color: #0d2049;
    font-weight: 500;
    background-color: white;
    z-index: 3;
  }

  .app_content {
    box-sizing:border-box;
    background-color: white;
    color: #0d2049;
    height: 95%;
    max-height: 95%;
    max-width: 88%;
    border-radius: 20px 0 0 20px;
    box-shadow: 0px 1px 5px rgba(2, 2, 69,0.7);
    padding: 0;
    align-items: start;
    justify-content: start;
  }
  
  .compliant_wrapper {
    width: 100%;
    height: 100%;
    font-size: 18px;
    padding: 0;
  }

  .compliant_box { 
    font-size: 15px;
    padding: 10px;
    margin-top: 10px;
    width: 95%;
    text-align: justify;
    align-items: start;
    flex-direction: column;
  }

  .compliant_options {
    padding: 0 10px;
    border-radius: 0 0 0 10px;
  }

  .compliant_selection {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    width: 100%;
  }
  .compliant_selection span {
    display: none;
  }

  .compliant_selection button {
    width: 100px;
    height: 50px;
    font-size: 14px;
    
  }

  .compliant_numbers {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .compliant_numbers div{
    display: flex;
    flex-direction: column;
    margin: 2px 0;
  }
  
  .risk_modal_label {
    width: 50px;
    text-align: center;
    font-size: 16px;
  }

  .risk_modal_number {
    width: 50px;
    height: 40px;
    margin-right: 5px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
  }

  .rpn_crit_box {
    display: flex;
    flex-direction: row !important;
  }


  .rpn_crit_box div{
    display: flex;
    flex-direction: column;
  }

  .rpn_crit_box:nth-child(2) {
    border: 1px solid #0d2049;
    border-radius: 10px;
    padding: 0 5px;
  }

  .rpn_label {
    text-align: center;
    width: 120px;
  }

  .rpn {
    width: 120px;
    height: 45px;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    border-radius: 5px;
    color: white
  }

  .criticality_label{
    text-align: center;
    width: 120px;
    margin-left: 10px;
  }

  .rpn_criticality {
    text-align: center;
    width: 120px;
    height: 45;
    font-size: 22px;
    line-height: 45px;
    margin-left: 10px;
  }

  .compliant_custom_wrapper  {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px 10px 0 0 ;
    color: white;
  }

  .compliant_custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }


.custom_max {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}

.custom_label {
  font-size: 14px;
  text-align: left;
}

.custom_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.custom_formula_box{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom_formula {
  height: 85px;
  width: 100%;
  font-size: 16px;
}

.custom_submit {
  position: relative;
  right: 10px;
  font-size: 16px;
  height: 35px;
}

.feature_box {
  flex-direction: column;
  justify-content: space-between;
}

.feature_left {
  position: relative;
  box-sizing: border-box;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}


.feature_right {
  width: 100%;
}

.feature_help {
  position: static;
  justify-content: center;

}

.feature_img_btn_right {
  display: none;
}

.feature_img_btn_left {
  display:none;
}

.img_modal_btn_box_right {
  display: none;
}

.img_modal_btn_box_left {
  display: none;
}

}